import React from 'react'
import {  faFacebookF , faGoogle,faInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StepButton } from '@material-ui/core'
import * as layoutStyles from "./layout.module.scss"

const Footer = ()=>{
    
    return (
        <footer>
            <div style= {Styles.padding}>
                
                <li className={layoutStyles.nobull}>
                    <p style={Styles.floatLeft} >Follow us on :</p>
                    <a  style={Styles.link} href="https://www.facebook.com/Scoop-Smile-101977728789798"><FontAwesomeIcon style={Styles.icon} icon={faFacebookF}/></a>
                    <a style={Styles.link}href="https://www.instagram.com/scoop__and__smile/"><FontAwesomeIcon style={Styles.icon} icon={faInstagram}></FontAwesomeIcon></a>
                </li>
            </div>
        </footer>
    )
}
const Styles={
    icon:{color: 'white',},
    padding:{paddingLeft: '25px', paddingTop:'25px'},
    link:{paddingLeft:'40px'},
    floatLeft:{float:'Left'}
}
export default Footer