import React from "react";
import Navbar from "./navbar";
import  PropTypes from "prop-types";
import Helmet from 'react-helmet'
import * as layoutStyles from "./layout.module.scss"
import {Link, graphql, StaticQuery} from 'gatsby'
import Footer from './footer'
import "../styles/index.scss"

const Layout = ({children,data}) => (
    <StaticQuery
        query={graphql`
        query SiteTitleQuery{
            site{
                siteMetadata{
                    title
                }
            }
        }
    `}
    render={data=>(
        <>
        <div className= "App">
            <Helmet 
                title={data.site.siteMetadata.title}
                meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
            >
                <html lang='en'/>
            </Helmet>
            <Navbar/>

            <div className={layoutStyles.container}>
                {children}
            </div>
            <div className={layoutStyles.footer}>
                <Footer/>   
            </div>
        </div>
        </>
        )} 
    />
    )
    Layout.propTypes = {
        children: PropTypes.node.isRequired,
    }


export default Layout