import React, { Component } from "react";
import "/src/styles/bootstrap.css";
import "/src/styles/navbarStyle.css";
import logo from "../images/logo.png"
import {Link} from 'gatsby'
import {Auth} from "@aws-amplify/auth";
import { navigate } from '@reach/router'
import { logout, isLoggedIn, getCurrentUser } from "../util/auth"
import { Checkbox } from "@material-ui/core";
import { ButtonCheck } from "../util/auth";
import {  faFacebookF , faGoogle,faInstagram} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


class Navbar extends React.Component{
    
    render(){
        return(
        <div id="bg-img">
            <div className="container">
                <div className="topnav">
                    <div id={"home"} >
                        <Link id={"btn"} to="/"><img style={{margin:'0',borderRadius:'100px'}} width="150vw"  src={logo}></img></Link>
                    </div>
                    <div className="topnav-link" id={"topnav-right"}>
                        {/*<Link className="navlink" style={{fontSize:'large'}} to="/app/news/">NEWS</Link>*/}
                        <Link className="navlink" style={{fontSize:'large'}} to="/app/menu/">MENU</Link>
                        <Link className ="navlink" style={{fontSize:'large'}} to="/app/contact/">CONTACT</Link>
                        <div>
                            <a  style={Styles.link} href="https://www.facebook.com/Scoop-Smile-101977728789798"><FontAwesomeIcon style={Styles.icon} icon={faFacebookF}/></a>
                            <a style={Styles.link}href="https://www.instagram.com/scoop__and__smile/"><FontAwesomeIcon style={Styles.icon} icon={faInstagram}></FontAwesomeIcon></a>
                        </div>
                        {/*<Link to={isLoggedIn()?"/app/profile/":'/app/login'}><p>My Profile</p></Link>
                        {isLoggedIn()?<button className={"signOut"} id={"signOut"}  onClick={() => Auth.signOut().then(logout(() => navigate("../..").catch(err => console.log('eror:', err))))}><p>Sign Out</p></button>:null}*/}
                    </div>
                </div>
            </div>
        </div>
        )
    }

}
const Styles={
    icon:{color: 'white',fontSize:'large'},
    padding:{paddingLeft: '25px', paddingTop:'25px'},
    link:{paddingLeft:'40px'},
    floatLeft:{float:'Left'}
}
export default Navbar